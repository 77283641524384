import React, { useEffect, useState } from 'react'
import styles from './index.module.css'
import Header from '../components/header'
import RoundedButton from '../components/roundedButton'
import RoundedInput from '../components/roundedInput'
import { Camera, Delete, Info, Photo, Search, Videocam } from '@mui/icons-material'
import { Checkbox, Modal } from '@mui/material'
import { checkboxClasses } from "@mui/material/Checkbox";
import NewPropertyModal from '../components/newPropertyModal'
import ApiService from '../ApiService'
import { PropertyResponse, UserResponse } from '../models'
import EditIcon from '@mui/icons-material/Edit';
import InsertPhotoIcon from '@mui/icons-material/InsertPhoto';
import { useNavigate } from 'react-router-dom'
import Loading from '../components/loading'
import DeleteModal from '../components/deleteModal'

export default function Imoveis() {
  const navigate = useNavigate()
  const [userData, setUserData] = useState<UserResponse>()
  const [newPropertyModal, setNewPropertyModal] = useState<boolean>(false)
  const [properties, setProperties] = useState<PropertyResponse[]>()
  const [landlordList, setLandlordList] = useState<UserResponse[]>()
  const [tenantList, setTenantList] = useState<UserResponse[]>()
  const [imagesList, setImagesList] = useState<any[]>()
  const [selectedProperty, setSelectedProperty] = useState<PropertyResponse | undefined>()
  const [page, setPage] = useState<number>(1)
  const [lastFetched, setLastFetched] = useState<number>()
  const [fetching, setFetching] = useState<boolean>(false)
  const [searchTerm, setSearchTerm] = useState<string>()
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [openDeleteModal, setOpenDeleteModal] = useState<PropertyResponse | undefined>()
  

  const tableColumns = [
    'Cód. Imóvel',
    'Endereço',
    'CEP',
    'Bairro',
    'Cidade',
    '',
  ]

  if(userData?.account_type === 'admin' || userData?.account_type === 'IMOBILIARIA') {
    tableColumns.splice(1,0,'Proprietário')
    tableColumns.push('')
  }

  if(userData?.account_type === 'PROPRIETARIO') {
    tableColumns.splice(1,0,'Inquilino')
  }

  const handleDeleteProperty = async (property: PropertyResponse | undefined) => {
    try {
        await ApiService.deleteProperty(property?.id?.toString() ?? '')
    }
    catch (error: any) {
        console.log(error)
    }
}

  const handleSearch = async (term: string) => {
    const trimmedSearchTerm = term.trim();
    const encodedSearchTerm = encodeURIComponent(trimmedSearchTerm);
    setSearchTerm(trimmedSearchTerm)
  
    try {
      if (term && term?.length >= 4) {
        const response = await ApiService.getPropertyBySearchTerm(encodedSearchTerm)
        const data = response.data as PropertyResponse[]
        setProperties(data);
        return
      }
      if (!term || term === '') {
        getPropertiesList(1)
      }
    } catch (error:any) {
      if (error?.name === 'AbortError') {
        // Ignore the request cancellation error
      } else {
        console.error('Error searching users:', error);
      }
    }
  }



  const getPropertiesList = async (page:number) => {
    setIsLoading(true)
    try{
      let actualData = properties
      const data:PropertyResponse[] = await ApiService.getProperty('all',page) as PropertyResponse[]
      if(actualData && page !== 1) {
        actualData?.push(...data)
      }
      else {
        actualData = data
      }
      setProperties(actualData)
      setLastFetched(data.length)
    }
    catch (error: any) {
      console.log(error)
    }
    finally{
      setIsLoading(false)
    }
  }

  const handleScroll = async (e:any) => {
    const { scrollTop, clientHeight, scrollHeight } = e.target;
    const bufferHeight = 50;
    // Check if the user has scrolled to the bottom
    if (
        Math.floor(scrollTop + clientHeight) >= Math.floor(scrollHeight/2) - bufferHeight && 
        lastFetched && lastFetched >= 100 && 
        !fetching &&
        searchTerm !== ''
      ) {
      setFetching(true)
      await getPropertiesList(page+1);
      setPage(page => page+1)
      setFetching(false)
    }
  };

  /*const handleGetLandlordList = async () => {
    const landlord = await ApiService.getUserByType('PROPRIETARIO')
    setLandlordList(landlord)
  }

  const handleGetTenantList = async () => {
    const tenant = await ApiService.getUserByType('INQUILINO')
    setTenantList(tenant)
  }*/

  const getUserFullName = (user: UserResponse | undefined) => {
    return user ? `${user.first_name} ${user.last_name}` : '';
  }

  useEffect(() => {
    getPropertiesList(1)
    //handleGetLandlordList()
    //handleGetTenantList()
    const user = JSON.parse(localStorage.getItem('userData') ?? '')
    if(user) {
      setUserData(user)
    }
    else {
      navigate('/login')
    }
    
  },[])

  return (
    <div className={styles.background}>
        <Modal open={newPropertyModal} onClose={() => setNewPropertyModal(false)}>
          <NewPropertyModal 
            closeCallback={() => setNewPropertyModal(false)} 
            propertyToEdit={selectedProperty}
          />
        </Modal>
        <Modal open={openDeleteModal? true : false} onClose={() => setOpenDeleteModal(undefined)}>
          <DeleteModal resourceToDelete={openDeleteModal?.id ?? 0} onCloseCallback={() => setOpenDeleteModal(undefined)} onAcceptCallback={() => handleDeleteProperty(openDeleteModal)}/>
        </Modal>
        <Header />
        <p className={styles.pageTitle}>Lista de Imóveis</p>
        <div className={styles.menuRow}>
          {(userData?.account_type === 'admin' || userData?.account_type === 'IMOBILIARIA') &&
            <div className={styles.newPropertyButton}>
              <RoundedButton buttonText='Novo Imóvel' buttonType='button' onClickCallback={() => {setNewPropertyModal(true); setSelectedProperty(undefined)}}/>
            </div>
          }
          <div className={styles.searchPropertyInput}>
            <Search />
            <RoundedInput disableLabel label='Pesquisar Imóvel' type='text' onChangeCallback={handleSearch} />
          </div>
        </div>
        <table className={styles.table}>
              <tr className={styles.tr}>
                {tableColumns.map((column) => 
                  {if(column === 'Cód. Imóvel') {
                    return (
                      <td className={styles.smallHeaderColumn}>
                        {column}
                      </td>
                    )}
                  if(column === '') {
                    return(<td className={styles.mediumHeaderColumn}>
                        {column}
                    </td>
                  )}
                  if(column === 'CEP' || column === 'Cidade') {
                    return(<td className={styles.mediumHeaderColumn}>
                        {column}
                    </td>
                  )}
                  return(
                    <td className={styles.th}>
                        {column}
                    </td>)
                  }
                )}
              </tr>
              <tbody className={styles.tableBody} onScroll={handleScroll}>
                {isLoading && <Loading/>}
                {properties?.map((property) =>
                  <tr>
                    <td className={styles.smallColumn}>
                      {property.code}
                    </td>
                    {(userData?.account_type === 'admin' || userData?.account_type === 'IMOBILIARIA') && <>
                      <td className={styles.td}>
                        {getUserFullName(property?.landlord_details)}
                      </td>
                    </>}
                    {(userData?.account_type === 'PROPRIETARIO') && <>
                      <td className={styles.td}>
                        {getUserFullName(property?.landlord_details)}
                      </td>
                    </>}
                    <td className={styles.td}>
                      {`${property.address} ${property.address_no}`}
                    </td>
                    <td className={styles.mediumColumn}>
                      {property.cep}
                    </td>
                    <td className={styles.td}>
                      {property.address_neighborhood}
                    </td>
                    <td className={styles.mediumColumn}>
                      {property.city}
                    </td>
                    <td className={styles.mediaTd}>
                      <div className={styles.iconsContainer} onClick={() => navigate('/imovel',{state: {property: property, tenantList: tenantList, landlordList: landlordList}})}>
                        <Photo className={property.num_images > 0? styles.yellowIcons : styles.grayIcons}/>
                        <Videocam className={property.num_videos > 0? styles.greenIcons : styles.grayIcons}/>
                      </div>
                    </td>
                    {(userData?.account_type === 'admin' || userData?.account_type === 'IMOBILIARIA') && <>
                      <td className={styles.mediaTd}>
                        <div className={styles.iconsContainer}>
                          <div className={styles.blackIcons} onClick={() => {setSelectedProperty(property); setNewPropertyModal(true)}}>
                            <EditIcon />
                          </div>
                          <div className={styles.redIcons} onClick={() => setOpenDeleteModal(property)}>
                            <Delete />
                          </div>
                        </div>
                      </td>
                    </>}
                  </tr>
                )}
              </tbody>
          </table>
    </div>
  )
}
