import React, { useState } from 'react'
import styles from './index.module.css'
import { FileUpload } from '@mui/icons-material';

interface Props {
    label: string;
    onChangeCallback: (file: string | FileList | null) => void;
}

export default function UploadField(props:Props) {
    const [inputCount, setInputCount] = useState(0)

    const handleInputChange = (files: string | FileList | null) => {
        props.onChangeCallback(files)
        setInputCount(files?.length ?? 0)
    }

  return (
    <div className={styles.container}>
        <input
            id={props.label}
            className={styles.input} 
            placeholder={props.label} 
            type='file'
            onChange={(e) => handleInputChange(e.target.files)}
            multiple
        />
        <label className={styles.label} htmlFor={props.label}>{inputCount === 0? props.label : `${inputCount} arquivos`}</label>
        <FileUpload  className={styles.icon}/>
    </div>
  )
}
