import { MenuItem, Select } from '@mui/material'
import React from 'react'
import styles from './index.module.css'

interface Props {
    label: string;
    value: string;
    onChange: (value: string) => void;
    items: string[];
}

export default function RoundedSelect(props: Props) {
  return (
    <Select
        style={{
            width: '110%',
            minWidth: '100px',
            height: '110%',
            borderRadius: '15px',
            border: '#ccc 2px solid',
            paddingLeft: '10px',
            paddingRight: '10px',
            color: 'black',
        }}
        label={props.label}
        value={props.value ?? props.label}
        onChange={(e) => props.onChange(e.target.value)}
        sx={{
            color: "#1e1e1e",
            '.MuiOutlinedInput-notchedOutline': {
              borderColor: 'rgba(228, 219, 233, 0.0)',
            },
            '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
              borderColor: 'rgba(228, 219, 233, 0.0)',
            },
            '&:hover .MuiOutlinedInput-notchedOutline': {
              borderColor: 'rgba(228, 219, 233, 0.0)',
            },
            '.MuiSvgIcon-root ': {
              fill: "#1e1e1e",
            }
        }}
    >
        <MenuItem value={props.label}>{props.label}</MenuItem>
        {props.items.map((item) => 
            <MenuItem value={item}>{item}</MenuItem>
        )}
    </Select>
  )
}
