import React, { Children, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { UserResponse } from '../../../models'

export default function ImobiliariaRoute(props: { children: React.ReactNode }) {
    const navigate = useNavigate()
    const getUser = async () => {
        const user = localStorage.getItem('userData')
        if(!user) {
            navigate('/login')
        }
        const userData:UserResponse = await JSON.parse(user ?? '')
        if(userData?.account_type !== 'admin' && userData?.account_type !== 'IMOBILIARIA') {
            navigate('/imoveis')
        }
    }
    useEffect(() => {
        getUser()
    },[])
    return (
        <>{props.children}</>
  )
}
