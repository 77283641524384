import React, { useEffect, useState } from 'react'
import styles from './index.module.css'
import Header from '../components/header'
import ApiService from '../ApiService'
import { LogResponse } from '../models'
import Loading from '../components/loading'
import { parseDate } from '../utils/parseDate'

export default function Logs() {
    const [page, setPage] = useState<number>(1)
    const [lastFetched, setLastFetched] = useState<number>()
    const [fetching, setFetching] = useState<boolean>(false)
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [logs, setLogs] = useState<LogResponse[]>()

    const tableColumns = [
        'Horário',
        'Autor',
        'Ação',
        'Imóvel/Usuário',
    ]

    const handleGetLogs = async (page:number) => {
        try {
            const response = await ApiService.getLogs('all',page)
            setLogs(response)
        }
        catch (error: any) {
            console.log(error)
        }
    }

    const handleScroll = async (e:any) => {
        const { scrollTop, clientHeight, scrollHeight } = e.target;
        const bufferHeight = 50;
        // Check if the user has scrolled to the bottom
        if (
            Math.floor(scrollTop + clientHeight) >= Math.floor(scrollHeight/2) - bufferHeight && 
            lastFetched && lastFetched >= 100 && 
            !fetching
          ) {
          setFetching(true)
          await handleGetLogs(page+1);
          setPage(page => page+1)
          setFetching(false)
        }
      };

    useEffect(() => {
        handleGetLogs(1)
    },[])

  return (
    <div className={styles.background}>
        <Header />
        <p className={styles.pageTitle}>Logs</p>
        <table className={styles.table}>
            <tr className={styles.tr}>
                {tableColumns.map((column) =>
                    <td className={styles.th}>{column}</td>
                )}
            </tr>
            <tbody className={styles.tableBody} onScroll={handleScroll}>
                {isLoading && <Loading/>}
                {logs?.map((log) =>
                    <tr>
                        <td className={styles.td}>
                            {parseDate(log.timestamp)}
                        </td>
                        <td className={styles.td}>
                            {`${log.actor_first_name} ${log.actor_last_name}`}
                        </td>
                        <td className={styles.td}>
                            {log.action}
                        </td>
                        <td className={styles.td}>
                            {log.object_detail}
                        </td>
                    </tr>
                )}
            </tbody>
        </table>
    </div>
  )
}
