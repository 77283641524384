import React, { useEffect, useState } from 'react'
import styles from './index.module.css'
import RoundedButton from '../roundedButton'
import RoundedInput from '../roundedInput'
import RoundedSelect from '../roundedSelect'
import { brazilianStates } from '../../utils/brazilianStates'
import UploadField from '../uploadField'
import { useForm } from 'react-hook-form'
import ApiService from '../../ApiService'
import { AccountTypes, PropertyRequest, PropertyResponse, UserResponse } from '../../models'
import LoadingButton from '../loadingButton'
import FeedbackComponent from '../feedbackComponent'
import RoundedInputSelect from '../roundedInputSelect'

interface Props {
    closeCallback: () => void
    propertyToEdit: PropertyResponse | undefined
}

interface FormValues {
    propertyId: number;
    landlord: string;
    tenant: string;
    address: string;
    addressNo: string;
    addressComplement: string;
    addressNeighborhood: string;
    city: string;
    state: string;
    cep: string;
    country: string;
    details: string;
    images: string | FileList | null;
    videos: string | FileList | null;
}

export default function NewPropertyModal(props: Props) {
    const { handleSubmit, setValue, watch } = useForm<FormValues>()
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [feedback, setFeedback] = useState<{type: 'success' | 'error', message: string} | undefined>()
    const [tenantList, setTenantList] = useState<UserResponse[]>([])
    const [landlordList, setLandlordList] = useState<UserResponse[]>([])

    const getCep = async (cep: string) => {
        const address = await (await fetch(`https://viacep.com.br/ws/${cep}/json/`)).json()
        setValue('address',address?.logradouro)
        setValue('addressNeighborhood', address?.bairro)
        setValue('city',address?.localidade)
        setValue('state', address?.uf)
    }

    const getUser = async (value: string) => {
        const response = await ApiService.getUserBySearchTerm(value)
        if (response?.data) {
            const uniqueTenants = [
                ...tenantList, 
                ...response.data.filter(u => u?.account_type === 'INQUILINO')
            ].reduce((acc:UserResponse[], user) => {
                if (!acc.some(existingUser => existingUser.id === user.id)) {
                    acc.push(user);
                }
                return acc;
            }, []);

            const uniqueLandlords = [
                ...landlordList, 
                ...response.data.filter(u => u?.account_type === 'PROPRIETARIO')
            ].reduce((acc:UserResponse[], user) => {
                if (!acc.some(existingUser => existingUser.id === user.id)) {
                    acc.push(user);
                }
                return acc;
            }, []);

            setTenantList(uniqueTenants);
            setLandlordList(uniqueLandlords); // Corrected 'setLandLordList'
        }
    }

    const handleSendData = async (data: FormValues) => {
        setIsLoading(true)
        try {
            const request:PropertyRequest = {
                code: data.propertyId,
                landlord: landlordList?.find(landlord => `${landlord?.first_name} ${landlord?.last_name}` === data?.landlord)?.id ?? 0,
                tenant: tenantList?.find(tenant => `${tenant?.first_name} ${tenant?.last_name}` === data?.tenant)?.id ?? 0,
                cep: data.cep,
                address: data.address,
                address_no: data.addressNo,
                address_l2: data.addressComplement,
                address_neighborhood: data.addressNeighborhood,
                city: data.city,
                state: data.state,
                country: data.country,
                details: data.details,
                image_folder: null,
                video_folder: null
            }
            if (props.propertyToEdit){
                request.id = props.propertyToEdit.id 
                const response = await ApiService.updateProperty(request)
            }
            else {                                                                                          
                const response = await ApiService.createProperty(request)
            }
            setFeedback({type:'success', message:'Imóvel criado com sucesso!'})
            props.closeCallback()
        } catch (error: any) {
            console.log(error)
            setFeedback({type:'error', message:'Houve um erro ao criar o imóvel'})
        }
        finally {
            setIsLoading(false)
        }
    }

    const getUserFullName = (user: UserResponse | undefined) => {
        return user ? `${user.first_name} ${user.last_name}` : '';
      }

    useEffect(() => {
        setValue('country', 'Brasil')
        setValue('state',"SP")
    },[])

    useEffect(() => {
        setTimeout(() => {
            setFeedback(undefined)
        },5000)
    },[feedback])

    useEffect(() => {
        if (props.propertyToEdit) {
            console.log(props.propertyToEdit)
            setValue('propertyId',props.propertyToEdit.code)
            setValue('landlord', getUserFullName(props?.propertyToEdit?.landlord_details))
            setValue('tenant', getUserFullName(props?.propertyToEdit?.tenant_details))
            setValue('cep',props.propertyToEdit.cep)
            setValue('address',props.propertyToEdit.address)
            setValue('addressNo', props.propertyToEdit.address_no)
            setValue('addressComplement', props.propertyToEdit.address_l2)
            setValue('addressNeighborhood', props.propertyToEdit.address_neighborhood)
            setValue('city', props.propertyToEdit.city)
            setValue('state', props.propertyToEdit.state)
            setValue('country', props.propertyToEdit.country)
            setValue('details', props.propertyToEdit.details)
        }
    },[props.propertyToEdit  ])

    useEffect(() => {
        const cep = watch('cep')
        if(cep && cep.length === 8) {
            getCep(cep)
        }
    },[watch('cep')])


    useEffect(() => {
        if(watch('landlord')) {
            getUser(watch('landlord'))
        } 
        if(watch('tenant')) {
            getUser(watch('tenant'))
        }
    },[watch('landlord'), watch('tenant')])

  return (
    <div className={styles.background}>
        <div className={styles.backButton}>
            <RoundedButton buttonText='Voltar' buttonType='button' onClickCallback={props.closeCallback}/>
        </div>
        <p className={styles.title}>Novo Imóvel</p>
        <form className={styles.forms} onSubmit={handleSubmit(handleSendData)}>
            <div className={styles.inputRow}>
                <div className={styles.mediumInput}>
                    <RoundedInput label='Código do imóvel' value={watch('propertyId')?.toString()} disabled={props.propertyToEdit? true: false} type='number' onChangeCallback={(value:string) => setValue('propertyId',Number(value))}/>
                </div>
                <div className={styles.mediumInput}>
                    <RoundedInputSelect label='Proprietário' value={watch('landlord')} items={landlordList?.map((landlord) => `${landlord?.first_name} ${landlord?.last_name}`) ?? ['']} onChange={(value:string) => setValue('landlord',value)}/>
                </div>
                <div className={styles.mediumInput}>
                    <RoundedInputSelect label='Inquilino' value={watch('tenant')} items={tenantList?.map((tenant) => `${tenant?.first_name} ${tenant?.last_name}`) ?? ['']} onChange={(value:string) => setValue('tenant',value)}/>
                </div>
            </div>
            <div className={styles.inputRow}>
                <div className={styles.mediumInput}>
                    <RoundedInput label='Endereço' value={watch('address')} type='text' onChangeCallback={(value:string) => setValue('address',value)}/>
                </div>
                <div className={styles.mediumInput}>
                    <RoundedInput label='Número' value={watch('addressNo')} type='number' onChangeCallback={(value:string) => setValue('addressNo',value)}/>
                </div>
                <div className={styles.mediumInput}>
                    <RoundedInput label='Complemento' value={watch('addressComplement')} type='text' onChangeCallback={(value:string) => setValue('addressComplement',value)}/>
                </div>
            </div>
            <div className={styles.inputRow}>
                <div className={styles.mediumInput}>
                    <RoundedInput label='CEP' type='text' value={watch('cep')} onChangeCallback={(value:string) => setValue('cep',value)}/>
                </div>
                <div className={styles.mediumInput}>
                    <RoundedInput label='Bairro' type='text' value={watch('addressNeighborhood')} onChangeCallback={(value:string) => setValue('addressNeighborhood',value)}/>
                </div>
                <div className={styles.mediumInput}>
                    <RoundedInput label='Cidade' type='text' value={watch('city')} onChangeCallback={(value:string) => setValue('city',value)}/>
                </div>
            </div>
            <div className={styles.lastInputRow}>
                <div className={styles.mediumInput}>
                    <RoundedSelect label='Estado' value={watch('state')} items={brazilianStates} onChange={(value:string) => setValue('state',value)}/>
                </div>
                <div className={styles.mediumInput}>
                    <RoundedInput label='País' type='text' value={watch('country')} onChangeCallback={(value:string) => setValue('country',value)}/>
                </div>
            </div>
            <div className={styles.inputRow}>
                <div className={styles.tallInput}>
                    <RoundedInput label='Observações' value={watch('details')} multiline={true} type='text' onChangeCallback={(value:string) => setValue('details',value)} />
                </div>
            </div>
            <div className={styles.inputRow}>
                {feedback? 
                    <div className={styles.feedback}>
                        <FeedbackComponent type={feedback.type} message={feedback.message} />
                    </div>
                :
                    <div className={styles.sendButton}>
                        {isLoading?
                            <LoadingButton />
                        :
                            <RoundedButton buttonText='Salvar' buttonType='submit' />
                        }
                    </div>
                }   
            </div>
        </form>
    </div>
  )
}
