import React, { useState } from 'react'
import styles from './index.module.css'
import RoundedButton from '../roundedButton';
import RoundedInput from '../roundedInput';
import ApiService from '../../ApiService';

interface Props {
    resourceToDelete: string | number;
    onCloseCallback: () => void;
    onAcceptCallback: () => void;
}


export default function DeleteModal(props: Props) {
    const [password, setPassword] = useState<string>()

    const handleCheckPassword = async () => {
        const response = await ApiService.checkPassword({password: password})
        if (response.authenticated) {
            props.onAcceptCallback()
            props.onCloseCallback()
        } 
    }

  return (
    <div className={styles.background}>
        <p>Por favor, insira sua senha para excluir</p>
        <div className={styles.input}>
            <RoundedInput type='password' label='Senha' onChangeCallback={(value) => setPassword(value)}/>
        </div>
        <div className={styles.button} onClick={handleCheckPassword}>
            <RoundedButton buttonText='Excluir' buttonColor='red' buttonType='button' />
        </div>
        <div className={styles.button} onClick={props.onCloseCallback}>
            <RoundedButton buttonText='Cancelar' buttonType='button' />
        </div>
    </div>
  )
}
