import React, { useState } from 'react'
import styles from './index.module.css'
import RoundedInput from '../components/roundedInput'
import EmailIcon from '@mui/icons-material/Email';
import LockIcon from '@mui/icons-material/Lock';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import RoundedButton from '../components/roundedButton';
import { useForm } from 'react-hook-form';
import ApiService from '../ApiService';
import { useNavigate, useNavigation } from 'react-router-dom';
import LoadingButton from '../components/loadingButton';
import { UserResponse } from '../models';

interface FormData {
    email: string;
    password: string;
}

interface NewUserForm {
    nome: string;
    email: string;
    telefone: string;
    mensagem: string;
}

export default function LoginPage() {
    const navigate = useNavigate()
    const [showPassword, setShowPassword] = useState<boolean>(false)
    const [errorOnLogin, setErrorOnLogin] = useState<string>()
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [isNewUser, setIsNewUser] = useState<boolean>(false)

    const {register, handleSubmit, setValue} = useForm<FormData>()
    const {register: registerNewUser, handleSubmit: handleSubmitNewUser, setValue: setValueNewUser} = useForm<NewUserForm>()

    const handleCheckIn = async (data: FormData) => {
        setIsLoading(true)
        if(!data.email || !data.password) {
            return setErrorOnLogin('Por favor preencha seu e-mail e senha')
        }
        try{
            const response = await ApiService.login({
                email: data.email,
                password: data.password
            })
            ApiService.setTokenUsuarioAutenticado(response.token)
            localStorage.setItem('token',response.token)
            const userData: UserResponse = await ApiService.getUserById(data.email) as UserResponse
            localStorage.setItem('userData',JSON.stringify(userData))
            navigate('/imoveis')
        }
        catch(error: any) {
            if(error?.message === 'Request failed with status code 400') {
                return setErrorOnLogin('E-mail ou Senha inválidos')
            }
            if(error?.message === 'Network Error') {
                return setErrorOnLogin('Erro de conexão')
            }
            console.log(`error: ${error}`)
            return setErrorOnLogin('Erro desconhecido')
        }  
        finally{
            setIsLoading(false)
        }
    }

  return (
        <div className={styles.background}>
            {isNewUser?
                <div className={styles.newUserContainer}>
                    <img className={styles.logo} src='/assets/images/novolar_logo.png' />
                    <p>Referência no mercado, a Novolar oferece aos seus clientes soluções completas em consultoria imobiliária. Com mais de 60 anos de história, a empresa é reconhecida como uma das maiores e mais admiradas imobiliárias full-service do Brasil.</p>
                    <div className={styles.inputContainer}>
                        <RoundedInput label='Nome' type='text' onChangeCallback={(text) => setValueNewUser('nome',text)}/>
                    </div>
                    <div className={styles.inputContainer}>
                        <RoundedInput label='E-mail' type='text' onChangeCallback={(text) => setValueNewUser('email',text)}/>
                    </div>
                    <div className={styles.inputContainer}>
                        <RoundedInput label='Telefone' type='number' onChangeCallback={(text) => setValueNewUser('telefone',text)}/>
                    </div>
                    <div className={styles.inputContainer}>
                        <RoundedInput label='Mensagem' type='text' onChangeCallback={(text) => setValueNewUser('mensagem',text)}/>
                    </div>
                    <div className={styles.loginButton}>
                        <RoundedButton buttonText='Fale Conosco' buttonType='button' />
                    </div>
                    <p className={styles.forgotPasswordText} onClick={() => setIsNewUser(false)}>Fazer Login</p>
                </div>
            :
                <div className={styles.loginContainer}>
                    <img className={styles.logo} src='/assets/images/novolar_logo.png' />
                    <p className={styles.sivicTitle}>SIVIC</p>
                    <form onSubmit={handleSubmit(handleCheckIn)} className={styles.center}>
                        <div className={styles.inputContainer}>
                            <EmailIcon />
                            <RoundedInput label='E-mail' type='text' onChangeCallback={(text) => setValue('email',text)}/>
                        </div>
                        <div className={styles.inputContainer}>
                            <LockIcon />
                            <RoundedInput label='Senha' type={showPassword? 'text' : 'password'} onChangeCallback={(text) => setValue('password',text)}/>
                            <div className={styles.visibilityIcon} onClick={() => setShowPassword(!showPassword)}>
                                {showPassword? <VisibilityOff /> : <Visibility />}
                            </div>
                        </div>
                        <div className={styles.row}>
                            {errorOnLogin && <p className={styles.error}>{errorOnLogin}</p>}
                            <p className={styles.forgotPasswordText}>Esqueceu a senha?</p>
                        </div>
                        <div className={styles.loginButton}>
                            {isLoading? <LoadingButton /> : <RoundedButton buttonText='Login' buttonType='submit'/>}
                        </div>
                    </form>
                    <p className={styles.forgotPasswordText} onClick={() => setIsNewUser(true)}>Não tem cadastro?</p>
                </div>
            }
        </div>
  )
}
