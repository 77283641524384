import React from 'react'
import styles from './index.module.css'

interface Props {
    default: string;
    onChangeCallback: (date: string) => void;
}

export default function DateInput(props: Props) {
  return (
    <input className={styles.container} type='date' defaultValue={props.default} onChange={(e) => props.onChangeCallback(e.target.value)}></input>
  )
}
