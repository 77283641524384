import React, { useEffect, useState } from 'react'
import styles from './index.module.css'
import { UserResponse } from '../../models'
import CorporateFareIcon from '@mui/icons-material/CorporateFare';
import GroupIcon from '@mui/icons-material/Group';
import DescriptionIcon from '@mui/icons-material/Description';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { Menu } from '@mui/icons-material';
import LogoutIcon from '@mui/icons-material/Logout';
import { useNavigate } from 'react-router-dom';

export default function Header() {
    const navigate = useNavigate()
    const [userData, setUserData] = useState<UserResponse>()
    const [mobileMenu, setMobileMenu] = useState<boolean>(false)
    const [profileMenu, setProfileMenu] = useState<boolean>(false)


    const handleLogout = () => {
        localStorage.removeItem('token')
        navigate('/login')
    }

    const handleGetUserData = async () => {
        const data = await JSON.parse(localStorage.getItem('userData') ?? '')
        setUserData(data)
    }

    useEffect(() => {
        handleGetUserData()
    },[])

  return (
    <div className={styles.background}>
        <img className={styles.logo} src='/assets/images/novolar_logo.png' onClick={() => navigate('/imoveis')}/>
        <div className={mobileMenu? styles.mobileMenu : styles.menuRow}>
            <div className={styles.menuItem} onClick={() => navigate('/imoveis')}>
                <CorporateFareIcon /> 
                <p>Imóveis</p>
            </div>
            {(userData?.account_type === 'admin' || userData?.account_type === 'IMOBILIARIA') && <>
                <div className={styles.menuItem} onClick={() => navigate('/usuarios')}>
                    <GroupIcon /> 
                    <p>Usuários</p>
                </div>
                <div className={styles.menuItem} onClick={() => navigate('/logs')}>
                    <DescriptionIcon /> 
                    <p>Logs</p>
                </div>
                <div className={styles.menuItem}>
                    <CheckBoxIcon /> 
                    <p>Termos</p>
                </div>
            </>}
        </div>
        <div className={styles.menuRow} onClick={() => setProfileMenu(!profileMenu)}>
            <p>{`Bem-vindo, ${userData?.first_name}`}</p>
            <img className={styles.userPicture} src={userData?.photo ?? '/assets/images/user.png'} />
        </div>
        <div className={styles.mobileMenuIcon}>
            <Menu onClick={() => setMobileMenu(!mobileMenu)}/>
        </div>
        {profileMenu &&
            <div className={styles.profileMenu}>
                <div className={styles.menuItem} onClick={handleLogout}>
                    <LogoutIcon /> 
                    <p>Sair</p>
                </div>
            </div>
        }
    </div>
  )
}
