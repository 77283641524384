import React from "react";
import { Route, BrowserRouter, Routes } from "react-router-dom";
import LoginPage from "./login";
import Imoveis from "./imoveis";
import UsersPage from "./users";
import Imovel from "./imovel";
import Redirect from "./components/Redirect";
import Logs from "./logs";
import ImobiliariaRoute from "./components/Redirect/ImobiliariaRoute";

const Router = () => {
    return(
        <BrowserRouter>
            <Routes>
                <Route element={<Redirect />} path="/portal" />
                <Route element={<Redirect />} path="/portal/#/login" />
                <Route element={<Redirect />} path="/" />
                <Route element={<LoginPage />}  path="/login" />
                <Route element={<Imoveis />}  path="/imoveis" />
                <Route 
                    element={
                        <ImobiliariaRoute>
                            <UsersPage />
                        </ImobiliariaRoute>
                    }  
                    path="/usuarios" 
                />
                <Route element={<Imovel />}  path="/imovel" />
                <Route 
                    element={
                        <ImobiliariaRoute>
                            <Logs />
                        </ImobiliariaRoute>
                    } 
                    path="/logs" 
                />
            </Routes>
        </BrowserRouter>
    )
 }
 
 export default Router;