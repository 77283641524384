import React from 'react'
import styles from './index.module.css'

interface Props {
    label: string;
    type: 'number' | 'text' | 'password';
    onChangeCallback: (text: string) => void;
    multiline?: boolean
    value?: string;
    disabled?: boolean
    disableLabel?: boolean
}

export default function RoundedInput(props: Props) {
  return (
    props.multiline? 
      <textarea className={styles.input} placeholder={props.label} onChange={(e) => props.onChangeCallback(e.target.value)} />
    :
    <>
      {!props.disableLabel && <label className={props.value? styles.label : styles.hiddenLabel}>{props.label}</label>}
      <input disabled={props.disabled} value={props.value} className={styles.input} placeholder={props.label} type={props.type} onChange={(e) => props.onChangeCallback(e.target.value)}/>
    </>
  )
}
