import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

export default function Redirect() {
    const navigate = useNavigate()
    const getUser = () => {
        const user = localStorage.getItem('userData')
        if(user) {
            navigate('/imoveis')
        }
        else {
            navigate('/login')
        }
    }
    useEffect(() => {
        getUser()
    },[])
    return (
    <div>index</div>
  )
}
