export async function base64(file: Blob): Promise<string | null> {
    return new Promise((resolve, reject) => {
        try{
            const reader = new FileReader();
            reader?.readAsDataURL(file);
            reader.onload = function () {
                resolve(reader.result as string);
            };
            reader.onerror = function (error) {
                console.log('Error: ', error);
                reject(error);
            };
        }
        catch (error) {
            resolve(null)
        }
    });
  }