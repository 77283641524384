import React from 'react'
import styles from './index.module.css'

interface Props {
    message: string;
    type: 'success' | 'error'
}

export default function FeedbackComponent(props:Props) {
  return (
    <div className={styles[props.type]}>
        <p>{props.message}</p>
    </div>
  )
}
