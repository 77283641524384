import React from 'react'
import styles from './index.module.css'

interface Props {
    buttonText: string
    buttonType: 'button' | 'submit'
    onClickCallback?: () => void
    buttonColor?: 'yellow' | 'red'
}

export default function RoundedButton(props: Props) {
  return (
    <button className={(props.buttonColor && props.buttonColor=='red')? styles.redButton : styles.button} onClick={props.onClickCallback} type={props.buttonType}>
        <p>{props.buttonText}</p>
    </button>
  )
}
