import React, { useEffect, useState } from 'react'
import styles from './index.module.css'
import RoundedButton from '../roundedButton'
import { useForm } from 'react-hook-form'
import RoundedInput from '../roundedInput'
import RoundedSelect from '../roundedSelect'
import UploadField from '../uploadField'
import { accountTypes } from '../../utils/accountTypes'
import { UserRequest, UserResponse } from '../../models'
import { base64 } from '../../utils/base64'
import ApiService from '../../ApiService'
import LoadingButton from '../loadingButton'
import FeedbackComponent from '../feedbackComponent'

interface Props {
    closeCallback: () => void
    userToEdit: UserResponse | undefined
}

interface FormValues {
    email: string
    password: string
    firstName: string
    lastName: string
    typeAccount: 'admin' | 'IMOBILIARIA' | 'PROPRIETARIO' | 'INQUILINO' | 'CORRETOR';
    cpf: string;
    phone: string;
    photo: string | FileList | null;
}

export default function NewUserModal(props: Props) {
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const { handleSubmit, watch, setValue } = useForm<FormValues>()
    const [feedback, setFeedback] = useState<{type: 'success' | 'error', message: string} | undefined>()


    useEffect(() => {
        setTimeout(() => {
            setFeedback(undefined)
        },5000)
    },[feedback])

    useEffect(() => {
        if(props.userToEdit) {
            setValue('firstName', props.userToEdit?.first_name ?? '')
            setValue('lastName', props.userToEdit?.last_name ?? '')
            setValue('email', props.userToEdit.email)
            setValue('cpf', props.userToEdit.cpf)
            setValue('phone', props.userToEdit.phone)
            setValue('photo', props.userToEdit.photo)
            setValue('typeAccount', props.userToEdit.account_type)
        }
    },[props.userToEdit, setValue])

    const handleSendData = async (data: FormValues) => {
        setIsLoading(true)
        try{
            const photo = await base64(data.photo?.[0] as File)
            const request:UserRequest = {
                username: {
                    id: props.userToEdit?.id ?? null,
                    username: data.email,
                    email: data.email,
                    password: data.password,
                    first_name: data.firstName,
                    last_name: data.lastName
                },
                cpf: data.cpf,
                phone: Number(data.phone),
                photo: photo,
                account_type: data.typeAccount,
            }
            if(props.userToEdit) {
                const response = await ApiService.updateUser(request)
            }
            else {
                const response = await ApiService.createUser(request)
            }
            props.closeCallback()
        }
        catch (error: any) {
            console.log(error)
            setFeedback({type:'error', message:'Houve um erro ao criar o usuário'})
        }
        finally {
            setIsLoading(false)
        }
    }

    useEffect(() => {
        const cpf = watch('cpf')
        if(cpf && !props.userToEdit) {
            setValue('password', `${cpf?.slice(0,5)}`)
        }
    },[watch('cpf')])

  return (
    <div className={styles.background}>
        <div className={styles.backButton}>
            <RoundedButton buttonText='Voltar' buttonType='button' onClickCallback={props.closeCallback}/>
        </div>
        <p className={styles.title}>Novo Usuário</p>
        <form className={styles.forms} onSubmit={handleSubmit(handleSendData)}>
            <div className={styles.inputRow}>
                <div className={styles.mediumInput}>
                    <RoundedInput label='Primeiro Nome' type='text' value={watch('firstName')} onChangeCallback={(value:string) => setValue('firstName',value)}/>
                </div>
                <div className={styles.mediumInput}>
                    <RoundedInput label='Sobrenome' type='text' value={watch('lastName')} onChangeCallback={(value:string) => setValue('lastName',value)}/>
                </div>
            </div>
            <div className={styles.inputRow}>
                <div className={styles.mediumInput}>
                    <RoundedInput label='CPF' type='number' value={watch('cpf')} onChangeCallback={(value:string) => setValue('cpf',value)}/>
                </div>
                <div className={styles.mediumInput}>
                    <RoundedInput label='Telefone' type='number' value={watch('phone')} onChangeCallback={(value:string) => setValue('phone',value)}/>
                </div>
            </div>
            <div className={styles.inputRow}>
                <div className={styles.mediumInput}>
                    <RoundedInput label='E-mail' type='text' value={watch('email')} onChangeCallback={(value:string) => setValue('email',value)}/>
                </div>
                <div className={styles.mediumInput}>
                    <RoundedInput label='Senha' type='text' value={watch('password')} onChangeCallback={(value:string) => setValue('password',value)}/>
                </div>
            </div>
            <div className={styles.inputRow}>
                <div className={styles.mediumInput}>
                    <RoundedSelect label='Tipo de Usuário' value={watch('typeAccount')} items={accountTypes} onChange={(value) => setValue('typeAccount',value as 'admin' | 'IMOBILIARIA' | 'PROPRIETARIO' | 'INQUILINO' | 'CORRETOR')}/>
                </div>
            </div>
            <div className={styles.inputRow}>
                <div className={styles.fileInput}>
                    <UploadField label='Foto de perfil' onChangeCallback={(value) => setValue('photo',value)} />
                </div>
            </div>
            <div className={styles.inputRow}>
                {feedback? 
                    <div className={styles.feedback}>
                        <FeedbackComponent type={feedback.type} message={feedback.message} />
                    </div>
                :
                    <div className={styles.sendButton}>
                        {isLoading?
                            <LoadingButton />
                        :
                            <RoundedButton buttonText='Salvar' buttonType='submit' />
                        }
                    </div>
                }   
            </div>
        </form>
    </div>
  )
}
