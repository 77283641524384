import React from 'react'
import styles from './index.module.css'
import RefreshIcon from '@mui/icons-material/Refresh';

export default function Loading() {
  return (
    <div>
        <RefreshIcon className={styles.rotating}/>
    </div>
  )
}
